import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/FinishPhisical.svg"
import { Link } from "gatsby"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    title="Deschide containerul cu cardul fizic"
    description="Ține cardul în spațiul indicat timp de 4-5 secunde astfel încât cardul să acopere o suprafață cât mai mare a cititorului și containerul se va deschide (vă rugăm să urmăriți și legenda cu signalistica ledurilor de pe cititor)."
  >
    <Link to="/salut">
      <button
        style={{
          width: "100%",
          backgroundColor: "#fff",
          height: 48,
          border: "1px solid #ABB2BA",
          cursor: "pointer",
          color: "#2E3338",
          borderRadius: 8,

          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Inter",
        }}
      >
        Reincepe tutorialul
      </button>
    </Link>
  </TutorialLayout>
)

export default Second
